<template>
  <div class="activityDialog">
    <el-dialog
      top="3%"
      width="50%"
      :title="dialogTitle"
      :visible.sync="visible"
      :close-on-click-modal="false"
      :before-close="closeEvent"
    >
      <el-form ref="dialogForm" :model="form" label-width="120px">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="商品分类">
              <el-select
                v-model="form.categoryId"
                placeholder="请选择商品分类"
                style="width: 100%"
              >
                <el-option
                  :label="item.name"
                  :value="item.id"
                  v-for="item in categoryList"
                  :key="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="商品名称">
              <el-input v-model="form.name"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="是否优质商品">
              <el-radio-group v-model="form.highGrade">
                <el-radio label="Y">是</el-radio>
                <el-radio label="N">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="是否包邮">
              <el-radio-group v-model="form.isBy">
                <el-radio label="0">是</el-radio>
                <el-radio label="1">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="供货商">
              <el-input v-model="form.suppliers"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="供货商品ID">
              <el-input v-model="form.suppliersProductId"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="循环币最大抵扣">
              <el-input v-model="form.maxDk"></el-input>
            </el-form-item>
          </el-col>
          <el-col
            :span="24"
            v-for="(item, index) in form.specificationList"
            :key="index"
          >
            <el-form-item :label="'规格' + (index + 1)" prop="specification">
              <div style="display: flex; align-items: center">
                <el-input
                  v-model="item.specification"
                  placeholder="请输入规格名称"
                ></el-input>
                <el-input
                  type="number"
                  v-model="item.price"
                  style="margin: 0 5px"
                  placeholder="请输入商品单价"
                >
                  <template slot="append">元</template>
                </el-input>
                <el-input
                  v-model="item.inventory"
                  placeholder="请输入库存"
                ></el-input>
                <i
                  class="el-icon-circle-plus"
                  v-if="index === 0"
                  @click="addLine()"
                  style="
                    font-size: 30px;
                    color: #409eff;
                    margin-top: 5px;
                    cursor: pointer;
                    margin-left: 20px;
                  "
                ></i>
                <i
                  class="el-icon-remove"
                  v-else
                  @click="removeLine(index)"
                  style="
                    font-size: 30px;
                    color: #f00;
                    margin-top: 5px;
                    cursor: pointer;
                    margin-left: 20px;
                  "
                ></i>
              </div>
            </el-form-item>
          </el-col>

          <!-- 
          <el-col :span="12">
            <el-form-item label="权重">
              <el-input v-model="form.weight"></el-input>
            </el-form-item>
          </el-col> -->

          <el-col :span="24">
            <el-form-item label="商品描述">
              <el-input
                type="textarea"
                :rows="3"
                v-model="form.description"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="商品封面" prop="name">
              <el-upload
                class="avatar-uploader"
                :headers="headers"
                :show-file-list="false"
                action="/api/image/uploadOss"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
              >
                <img v-if="form.cover" :src="form.cover" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="详情长图">
              <el-upload
                ref="upload"
                :headers="headers"
                :file-list="fileList"
                :before-upload="beforeAvatarUpload"
                :on-success="handleSuccess"
                action="/api/image/uploadOss"
                list-type="picture-card"
              >
                <i slot="default" class="el-icon-plus"></i>
                <div slot="file" slot-scope="{ file }">
                  <img
                    class="el-upload-list__item-thumbnail"
                    :src="file.url"
                    alt=""
                  />
                  <span class="el-upload-list__item-actions">
                    <!-- <span
                      class="el-upload-list__item-preview"
                      @click="handlePictureCardPreview(file)"
                    >
                      <i class="el-icon-zoom-in"></i>
                    </span> -->
                    <span
                      v-if="!disabled"
                      class="el-upload-list__item-delete"
                      @click="handleRemove(file)"
                    >
                      <i class="el-icon-delete"></i>
                    </span>
                  </span>
                </div>
              </el-upload>
              <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt="" />
              </el-dialog>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="商品照片">
              <el-upload
                ref="upload2"
                :headers="headers"
                :file-list="fileList2"
                :before-upload="beforeAvatarUpload"
                :on-success="handleSuccess2"
                action="/api/image/uploadOss"
                list-type="picture-card"
              >
                <i slot="default" class="el-icon-plus"></i>
                <div slot="file" slot-scope="{ file }">
                  <img
                    class="el-upload-list__item-thumbnail"
                    :src="file.url"
                    alt=""
                  />
                  <span class="el-upload-list__item-actions">
                    <!-- <span
                      class="el-upload-list__item-preview"
                      @click="handlePictureCardPreview2(file)"
                    >
                      <i class="el-icon-zoom-in"></i>
                    </span> -->
                    <span
                      v-if="!disabled"
                      class="el-upload-list__item-delete"
                      @click="handleRemove2(file)"
                    >
                      <i class="el-icon-delete"></i>
                    </span>
                  </span>
                </div>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeEvent()">取 消</el-button>
        <el-button type="primary" :disabled="disabledSubmit" @click="submitData()">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      disabledSubmit:false,
      visible: false,
      dialogTitle: "新增商品",
      headers: {
        authorization: localStorage.getItem("token"),
      },
      memberId: "",
      form: {
        categoryId: "",
        cover: "",
        description: "",
        detailPicture: "",
        highGrade: "Y",
        inventory: 0,
        isBy: "0",
        maxDk: "",
        name: "",
        picture: "",
        suppliers:"",
        suppliersProductId:"",
        specificationList: [
          //规格列表
          {
            specification: "",
            price: "",
            inventory: "",
          },
        ],
        weight: 0,
      },
      userList: [], //商品数组
      categoryList: [], //分类列表
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      fileList: [],
      fileList2: [],
    };
  },
  methods: {
    addLine() {
      //新增一行
      this.form.specificationList.push({
        specification: "",
        price: "",
        inventory: "",
      });
    },
    removeLine(index) {
      //删除当前行
      this.form.specificationList.splice(index, 1);
    },
    handleRemove(file) {
      console.log(file);
      //删除封面图片
      const objIndex = this.fileList.findIndex((e) => e.url === file.url);
      if (objIndex !== -1) {
        this.fileList.splice(objIndex, 1);
      }
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleRemove2(file) {
      console.log(file);
      //删除封面图片
      const objIndex = this.fileList2.findIndex((e) => e.url === file.url);
      if (objIndex !== -1) {
        this.fileList2.splice(objIndex, 1);
      }
    },
    handlePictureCardPreview2(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleAvatarSuccess(res, file) {
      console.log(res, file);
      this.form.cover = res.data;
      console.log(this.form.cover);
    },
    beforeAvatarUpload(file) {
      this.disabledSubmit = true;
      const isLt2M = file.size / 1024 / 1024 < 10;

      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 10MB!");
      }
      return isLt2M;
    },
    async submitData() {
      let params = { ...this.form };
      params.detailPicture = this.fileList.map((e) => e.url).join(";");
      params.picture = this.fileList2.map((e) => e.url).join(";");
      params.specificationList = params.specificationList || [];
      let url = "";
      if (params.id) {
        //修改
        url = "api/admin/product/update";
      } else {
        url = "api/admin/product/add";
      }

      this.getAjax(url, params, "post").then(() => {
        this.$message.success("操作成功");
        this.closeEvent();
        this.$emit("search");
      });
    },
    closeEvent() {
      this.visible = false;
      this.dialogTitle = "新增商品";
      this.form = {};
    },
    handleSuccess(res) {
      this.disabledSubmit = false;
      this.fileList.push({
        name: res.data,
        url: res.data,
      });
    },
    handleSuccess2(res) {
      this.disabledSubmit = false;
      this.fileList2.push({
        name: res.data,
        url: res.data,
      });
    },
    getInfo(item) {
      this.dialogTitle = "编辑商品";
      this.visible = true;
      //获取分类列表
      this.getAjax(
        "api/admin/productCategory/list",
        {
          page: 1,
          size: 100,
        },
        "post"
      ).then(async (res) => {
        this.categoryList = res.data.records;
        const res2 = await this.getAjax(
          "api/admin/product/detail",
          { id: item.id },
          "post"
        );
        this.form = res2.data;
        this.$nextTick(() => {
          this.fileList = [];
          this.fileList2 = [];
          this.form.specificationList = this.form.specificationList || [
            {
              specification: "",
              price: "",
              inventory: "",
            },
          ];
          if (item.detailPicture) {
            this.fileList = item.detailPicture.split(";").map((e) => {
              return {
                name: e,
                url: e,
              };
            });
          }
          if (item.picture) {
            this.fileList2 = item.picture.split(";").map((e) => {
              return {
                name: e,
                url: e,
              };
            });
          }
        });
      });
    },
    initDialog() {
      this.form = {
        categoryId: "",
        cover: "",
        description: "",
        detailPicture: "",
        highGrade: "Y",
        inventory: 0,
        isBy: "0",
        maxDk: "",
        name: "",
        picture: "",
        specificationList: [
          {
            specification: "",
            price: "",
            inventory: "",
          },
        ],
        weight: 0,
      };
      this.fileList = [];
      this.fileList2 = [];
      //获取分类列表
      this.getAjax(
        "api/admin/productCategory/list",
        {
          page: 1,
          size: 100,
        },
        "post"
      ).then((res) => {
        console.log(res);
        this.categoryList = res.data.records;
      });
    },
  },
  mounted() {},
};
</script>

<style lang="less">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9 !important;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
